import React from "react";
import Arrowdown from "./Arrow/arrow";
import Line from "./Line/line";

export const FinalGoogleAds = () => {
  return (
    <div className="final-google-ads">
      <div className="div">
        <div className="overlap">
          <div className="overlap">
            <div className="rectangle" />
            <img
              className="ingelt-white"
              alt="Ingelt white"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/ingelt-white-3@2x.png"
            />
            <div className="element">
              <div className="overlap-group">
                <div className="circle">
                  <div className="overlap-group-2">
                    <div className="span-absolute" />
                    <div className="span-absolute-2" />
                  </div>
                </div>
                <div className="rectangle-2" />
                <img
                  className="img"
                  alt="Webinar"
                  src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/webinar--1--1@2x.png"
                />
                <p className="element-listed">
                  20,000+ Listed Properties&nbsp;&nbsp;across 5 countries.
                </p>
                <div className="text-wrapper">Live / Online Classes</div>
              </div>
            </div>
            <div className="rectangle-3" />
            <img
              className="group"
              alt="Group"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/group-21.png"
            />
            <div className="overlap-wrapper">
              <div className="overlap-2">
                <div className="ellipse" />
                <img
                  className="mask-group"
                  alt="Mask group"
                  src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/mask-group.png"
                />
                <div className="frame">
                  <div className="frame-wrapper">
                    <div className="div-wrapper">
                      <div className="text-wrapper-2">Student Visa</div>
                    </div>
                  </div>
                </div>
                <div className="frame-2">
                  <div className="frame-3">
                    <div className="div-wrapper">
                      <div className="text-wrapper-2">Work Visa</div>
                    </div>
                  </div>
                  <div className="ellipse-2" />
                </div>
                <div className="frame-4">
                  <div className="frame-5">
                    <div className="div-wrapper">
                      <div className="text-wrapper-2">Permanent Residence</div>
                    </div>
                  </div>
                  <div className="ellipse-3" />
                </div>
              </div>
            </div>
            <div className="ellipse-4" />
          </div>
          <p className="p">Start Your IELTS Preparation And Get Desire Bands</p>
          <p className="get-access-to-IELTS">
            Get access to IELTS Centralised Library
            <br />
            British Council Certified Teacher
          </p>
          <div className="overlap-group-wrapper">
            <div className="overlap-group">
              <div className="circle">
                <div className="overlap-group-2">
                  <div className="span-absolute" />
                  <div className="span-absolute-2" />
                </div>
              </div>
              <div className="rectangle-2" />
              <img
                className="img"
                alt="Release"
                src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/release--1--1@2x.png"
              />
              <p className="element-listed">
                20,000+ Listed Properties&nbsp;&nbsp;across 5 countries.
              </p>
              <div className="text-wrapper-3">Free IELTS Modules</div>
            </div>
          </div>
          <div className="element-2">
            <div className="overlap-group">
              <div className="circle">
                <div className="overlap-group-2">
                  <div className="span-absolute" />
                  <div className="span-absolute-2" />
                </div>
              </div>
              <div className="rectangle-2" />
              <img
                className="img"
                alt="Online learning"
                src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/online-learning--2--1@2x.png"
              />
              <p className="element-listed">
                20,000+ Listed Properties&nbsp;&nbsp;across 5 countries.
              </p>
              <div className="text-wrapper-4">Offline Classes</div>
            </div>
          </div>
          <div className="overlap-3">
            <div className="text-wrapper-5">Start</div>
            <Line
              className="line-instance"
              property1="line"
              property2="system"
              property3="checkbox-circle-line"
              propertyLine="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/line.svg"
            />
            <div className="text-wrapper-6">Confirm</div>
            <div className="group-2">
              <div className="overlap-4">
                <div className="text-wrapper-7">3</div>
              </div>
            </div>
            <div className="text-wrapper-8">Fill Detail</div>
            <div className="group-3">
              <div className="overlap-4">
                <div className="text-wrapper-7">2</div>
              </div>
            </div>
            <div className="text-wrapper-9">Free IELTS Classes</div>
            <div className="overlap-5">
              <div className="text-wrapper-10">Phone Number</div>
            </div>
            <div className="overlap-6">
              <img
                className="twemoji-flag-india"
                alt="Twemoji flag india"
                src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/twemoji-flag-india.svg"
              />
              <div className="text-wrapper-11">+91</div>
            </div>
            <div className="overlap-7">
              <div className="text-wrapper-10">Enter Your Full Name</div>
            </div>
            <div className="group-4">
              <div className="frame-6">
                <div className="text-wrapper-12">Continue</div>
              </div>
            </div>
            <p className="by-continuing-you">
              <span className="span">By continuing, you agree to our </span>
              <span className="text-wrapper-13">Term of services</span>
              <span className="span"> &amp; </span>
              <span className="text-wrapper-13">Privacy policy</span>
            </p>
          </div>
        </div>
        <div className="googel-ad">
          <div className="text-wrapper-14">Our Services</div>
          <div className="overlap-8">
            <div className="element-3">
              <div className="overlap-9">
                <div className="circle-2">
                  <div className="overlap-group-2">
                    <div className="span-absolute" />
                    <div className="span-absolute-2" />
                  </div>
                </div>
                <div className="text-wrapper-15">Accommodation</div>
                <p className="text-wrapper-16">
                  20,000+ Listed Properties&nbsp;&nbsp;across 5 countries.
                  <br />
                  100+cities
                </p>
              </div>
            </div>
            <div className="div-2">
              <img
                className="accommodation"
                alt="Accommodation"
                src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/accommodation-1@2x.png"
              />
            </div>
          </div>
          <div className="overlap-10">
            <div className="element-3">
              <div className="overlap-9">
                <div className="circle-2">
                  <div className="overlap-group-2">
                    <div className="span-absolute" />
                    <div className="span-absolute-2" />
                  </div>
                </div>
                <div className="text-wrapper-17">Centralized Community</div>
                <p className="text-wrapper-16">
                  Talk to international student community.
                  <br />
                  Ask your queries and doubts
                </p>
              </div>
            </div>
            <div className="div-2" />
            <img
              className="img-2"
              alt="Community"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/community-1@2x.png"
            />
          </div>
          <div className="overlap-11">
            <div className="element-3">
              <div className="overlap-9">
                <div className="circle-2">
                  <div className="overlap-group-2">
                    <div className="span-absolute" />
                    <div className="span-absolute-2" />
                  </div>
                </div>
                <div className="text-wrapper-17">Education Loan</div>
                <p className="text-wrapper-16">
                  100% Funding with quick loan sanction!
                  <br />
                  Pre-admission loan.
                </p>
              </div>
            </div>
            <div className="div-2" />
            <img
              className="img-2"
              alt="Real estate"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/real-estate--1--1@2x.png"
            />
          </div>
          <div className="overlap-12">
            <div className="element-3">
              <div className="overlap-9">
                <div className="circle-2">
                  <div className="overlap-group-2">
                    <div className="span-absolute" />
                    <div className="span-absolute-2" />
                  </div>
                </div>
                <div className="text-wrapper-17">Visa Application</div>
                <p className="text-wrapper-16">
                  By Industry leading Immigration Consultants.
                  <br />
                  Quick &amp; Hassle Free Visa Filing.
                </p>
              </div>
            </div>
            <div className="div-2" />
            <img
              className="img-2"
              alt="Boarding pass"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/boarding-pass-2@2x.png"
            />
          </div>
          <div className="group-5">
            <div className="overlap-13">
              <div className="ellipse-5" />
              <div className="group-6">
                <div className="overlap-group-3">
                  <div className="rectangle-4" />
                  <div className="rectangle-4" />
                  <div className="group-wrapper">
                    <div className="ellipse-wrapper">
                      <div className="ellipse-6" />
                    </div>
                  </div>
                </div>
              </div>
              <img
                className="women-png-img"
                alt="Women png img"
                src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/women-png-img-1.png"
              />
              <img
                className="board-logo-high"
                alt="Board logo high"
                src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/board-logo-high-1@2x.png"
              />
            </div>
          </div>
        </div>
        <div className="overlap-14">
          <img
            className="rectangle-5"
            alt="Rectangle"
            src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/rectangle-5422.svg"
          />
          <div className="rectangle-6" />
          <div className="rectangle-7" />
          <Arrowdown
            arrowdown="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/arrowdown-1.svg"
            className="arrowdown-instance"
          />
          <div className="number">
            <div className="text-wrapper-18">01</div>
            <div className="text-wrapper-19">/ 04</div>
          </div>
          <Arrowdown
            arrowdown="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/arrowdown.svg"
            className="design-component-instance-node"
          />
          <div className="group-7">
            <div className="text-wrapper-20">Freda Collier</div>
            <img
              className="divider"
              alt="Divider"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/divider.svg"
            />
          </div>
          <img
            className="quotes"
            alt="Quotes"
            src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/quotes-1.svg"
          />
          <img
            className="quotes-2"
            alt="Quotes"
            src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/quotes.svg"
          />
          <p className="text-wrapper-21">
            Ipsum vel nobis doloremque est aut non accusantium vero molestias.
            Et est minima dolorem eum modi atque sint nobis. Enim quod facere.
            Reiciendis necessitatibus ipsam non aspernatur voluptate id.
          </p>
          <img
            className="image"
            alt="Image"
            src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/image.png"
          />
          <div className="group-8">
            <img
              className="star"
              alt="Star"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/star-1.svg"
            />
            <img
              className="star-2"
              alt="Star"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/star-1.svg"
            />
            <img
              className="star-3"
              alt="Star"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/star-1.svg"
            />
            <img
              className="star-4"
              alt="Star"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/star-1.svg"
            />
            <img
              className="star-5"
              alt="Star"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/star-1.svg"
            />
          </div>
        </div>
        <div className="overlap-15">
          <div className="overlap-16">
            <div className="rectangle-8" />
            <div className="vector-wrapper">
              <img
                className="vector"
                alt="Vector"
                src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/vector-2.svg"
              />
            </div>
            <div className="img-wrapper">
              <img
                className="vector-2"
                alt="Vector"
                src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/vector-1.svg"
              />
            </div>
            <div className="group-9">
              <img
                className="vector-2"
                alt="Vector"
                src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/vector.svg"
              />
            </div>
            <div className="rectangle-9" />
            <img
              className="bxs-phone-call"
              alt="Bxs phone call"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/bxs-phone-call.svg"
            />
            <div className="text-wrapper-22">+1012 3456 789</div>
            <img
              className="ic-sharp-email"
              alt="Ic sharp email"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/ic-sharp-email.svg"
            />
            <div className="text-wrapper-23">demo@gmail.com</div>
            <img
              className="carbon-location"
              alt="Carbon location"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/carbon-location-filled.svg"
            />
            <p className="text-wrapper-24">
              132 Dartmouth Street Boston, Massachusetts 02156 United States
            </p>
            <div className="text-wrapper-25">Contact Information</div>
          </div>
          <h1 className="h-1">Contact Us</h1>
          <p className="text-wrapper-26">
            Feel Free to contact us any time. We will get back to you as soon as
            we can!.
          </p>
          <img
            className="vector-3"
            alt="Vector"
            src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/vector-6.svg"
          />
          <div className="text-wrapper-27">Email</div>
          <div className="text-wrapper-28">Email</div>
          <img
            className="vector-4"
            alt="Vector"
            src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/vector-6.svg"
          />
          <div className="text-wrapper-29">Name</div>
          <div className="text-wrapper-30">Doe</div>
          <img
            className="vector-5"
            alt="Vector"
            src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/vector-9.svg"
          />
          <div className="text-wrapper-31">Message</div>
          <div className="text-wrapper-32">Write your message..</div>
          <div className="text-wrapper-33">Send Message</div>
          <div className="overlap-17">
            <img
              className="line-2"
              alt="Line"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/line-373.svg"
            />
            <img
              className="line-3"
              alt="Line"
              src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/line-374.svg"
            />
          </div>
        </div>
        <p className="text-wrapper-34">Get in Touch with Us</p>
        <p className="text-wrapper-35">
          Elevate Your Institute Management and Student Success - Contact Us
          Now!
        </p>
        <img
          className="line-4"
          alt="Line"
          src="https://generation-sessions.s3.amazonaws.com/9aa3faccada56a994f35e06fcae1a9e0/img/line-1.svg"
        />
      </div>
    </div>
  );
};
